<template>
  <div>
    <Header/>

    <section id="order" v-if="orderData">
      <div class="inner w1300">
        <div class="breadcrumbs">
          <span class="txt-lightgrey">
            <a href="member_order.php">
              訂單查詢
            </a> > 訂單編號{{orderData.info.order_no}}
          </span>
        </div>

        <div class="order_area">
          <div class="area_title">
            <img src="@/assets/img/account/icon_title_3.svg" alt="">
            <div>
              <h4 class="txt-bold">
                訂單編號 {{orderData.info.order_no}}
              </h4>
              <router-link
                :to="{ name: 'OrderQuestionnaire' }" class="satisfaction_btn small">
                填寫滿意度問卷&nbsp;
                <img src="@/assets/img/account/icon_smile.svg" alt="">
              </router-link>
            </div>
          </div>

          <div class="area_detail">
            <!-- 訂單內容 -->
            <div class="detail_title open_cart_part">
              <h6 class="txt-bold">
                訂單內容
              </h6>
              <small
                class="seemore txt-grey"
                style="cursor: pointer; user-select: none;"
                @click="toggleIfInfoShow()">
                  <span v-text="(ifInfoShow) ? '收合' : '展開'"></span>&nbsp;
                  <img
                    src="@/assets/img/icon/icon_down-g.svg"
                    style="width:7px; margin-bottom:2px;"
                    alt="">
              </small>
            </div>
            <div class="detail_box cart_part" :class="{ active: ifInfoShow }">
              <div class="cart_area wifi_cart">
                <div class="cart_head">
                  <div class="cart_tr txt-white">
                    <div class="cart_td td-pic">
                      <h6 class="txt-bold">
                        <!-- WiFi 分享器 -->
                      </h6></div>
                    <div class="cart_td td-name"><small>名稱</small></div>
                    <div class="cart_td td-timezone txt-center"><small>租借區間</small></div>
                    <div class="cart_td td-time txt-center"><small>時間</small></div>
                    <div class="cart_td td-prize txt-center"><small>單價</small></div>
                    <div class="cart_td td-count txt-center"><small>數量</small></div>
                    <div class="cart_td td-total txt-center"><small>小計</small></div>
                  </div>
                </div>

                <div
                class="cart_item_box" v-for="(data, i) in orderData.details" :key="`detail_${i}`">
                  <div class="cart_item">
                    <div class="cart_tr">
                      <div class="cart_td td-pic">
                        <div class="picbox">
                          <figure
                            class="cart_pic"
                            :style="`background-image: url(${data.cover});`"
                            >
                          </figure>
                        </div>
                      </div>
                        <div class="cart_td td-name">
                          <div class="tag small">{{data.category}}</div>
                          <h6 class="txt-bold">{{data.product_name}}</h6>
                          <small class="txt-greenlake">流量: {{data.flow}}</small>
                        </div>
                        <div
                        v-if="data.abroad_at && data.backhome_at"
                        class="cart_td td-timezone center rwd-wifi" data-rwd="租借區間">
                          <p class="small">{{data.abroad_at}} － {{data.backhome_at}}</p>
                        </div>
                        <div class="cart_td td-time center rwd-wifi" data-rwd="時間" v-if="data.days">
                          <p>{{data.days}}天</p>
                        </div>
                        <div class="cart_td td-prize center rwd-wifi" data-rwd="單價" >
                          <div>
                            <p class="txt-redorange">
                              <vue-numeric
                              read-only currency="$"
                              separator="," :value="data.sell_price"></vue-numeric>
                               / 天</p>
                            <p class="txt-line-through" v-if="data.price">
                              <vue-numeric
                              read-only currency="$"
                              separator="," :value="data.price"></vue-numeric>
                               / 天</p>
                          </div>
                        </div>
                        <div class="cart_td td-count center rwd-wifi" data-rwd="數量" >
                          <div class="cart_number" data-max="10">
                            <p>{{data.qty}}</p>
                          </div>
                        </div>
                        <div class="cart_td td-total center rwd-wifi" data-rwd="小計" >
                          <div>
                            <p class="txt-redorange">
                              <vue-numeric
                              read-only currency="$" separator=","
                              :value="data.total"></vue-numeric>
                            </p>
                            <p class="txt-line-through"
                              v-if="data.price && data.days">
                              <vue-numeric
                              read-only currency="$" separator=","
                              :value="data.price * data.qty * data.days"></vue-numeric>
                            </p>
                            <p class="txt-line-through" v-else-if="data.price">
                              <vue-numeric
                              read-only currency="$" separator=","
                              :value="data.price * data.qty"></vue-numeric>
                            </p>
                          </div>
                        </div>
                    </div>
                    <template
                      v-if="Object.keys(data.pluses).length > 0"
                      >
                      <!-- 1:贈品 2:加購品 3:附加服務 -->
                      <template v-if="data.pluses['2'] && data.pluses['2'].length > 0">
                        <div
                          class="cart_tr tr-mutiple add_sale">
                          <div class="cart_td td-mutiple_title">
                            <p class="mutiple_title txt-white">
                              <span>加購</span>
                            </p>
                          </div>
                          <div class="cart_td td-add_inner">
                            <div class="cart_tr"
                              v-for="(plus, i) in data.pluses['2']" :key="`plus_${i}`">
                              <div class="cart_td td-add-product">
                                <div class="add_icon">
                                  <figure
                                    :style="
                                      `background-image: url(
                                        ${require('@/assets/img/shopping/product_add1.png')}
                                      );`
                                    ">
                                  </figure>
                                </div>
                                <div class="add_name">
                                  <h6>{{plus.product_name}}</h6>
                                  <!-- <small class="txt-greenlake">賠償費用大幅降低一半</small> -->
                                </div>
                              </div>
                              <div class="cart_td td-time center rwd-wifi" data-rwd="時間">
                                <p>{{plus.days}}</p>
                              </div>
                              <div class="cart_td td-prize center rwd-wifi" data-rwd="單價">
                                <div>
                                  <p>${{plus.price}} / 天</p>
                                </div>
                              </div>
                              <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                                <div class="cart_number" data-max="10">
                                  <p>{{plus.qty}}</p>
                                </div>
                              </div>
                              <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                                <div>
                                  <vue-numeric
                                    read-only currency="$" separator="," :value="plus.total">
                                  </vue-numeric>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="data.pluses['3'] && data.pluses['3'].length > 0">
                        <div
                          class="cart_tr tr-mutiple add_sale">
                          <div class="cart_td td-mutiple_title">
                            <p class="mutiple_title txt-white">
                              <span>附加服務</span>
                            </p>
                          </div>
                          <div class="cart_td td-add_inner">
                            <div class="cart_tr"
                              v-for="(plus, i) in data.pluses['3']" :key="`plus_${i}`">
                              <div class="cart_td td-add-product">
                                <div class="add_icon">
                                  <figure
                                    :style="
                                      `background-image: url(
                                        ${require('@/assets/img/shopping/product_add1.png')}
                                      );`
                                    ">
                                  </figure>
                                </div>
                                <div class="add_name">
                                  <h6>{{plus.product_name}}</h6>
                                  <!-- <small class="txt-greenlake">賠償費用大幅降低一半</small> -->
                                </div>
                              </div>
                              <div class="cart_td td-time center rwd-wifi" data-rwd="時間">
                                <p>{{plus.days}}</p>
                              </div>
                              <div class="cart_td td-prize center rwd-wifi" data-rwd="單價">
                                <div>
                                  <p>${{plus.price}} / 天</p>
                                </div>
                              </div>
                              <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                                <div class="cart_number" data-max="10">
                                  <p>{{plus.qty}}</p>
                                </div>
                              </div>
                              <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                                <div>
                                  <vue-numeric
                                    read-only currency="$" separator="," :value="plus.total">
                                  </vue-numeric>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="data.pluses['1'] && data.pluses['1'].length > 0">
                        <div class="cart_tr tr-mutiple add_gift">
                          <div class="cart_td td-mutiple_title">
                            <p class="mutiple_title txt-white">
                              <span>贈品</span>
                            </p>
                          </div>
                          <div class="cart_td td-add_inner">
                            <div class="cart_tr"
                              v-for="(plus, i) in data.pluses['1']" :key="`free_${i}`">
                              <div class="cart_td td-add-product details">
                                <div class="add_icon">
                                  <figure
                                    :style="
                                      `background-image: url(
                                        ${require('@/assets/img/shopping/product_2.png')}
                                      );`
                                    ">
                                  </figure>
                                </div>
                                <div class="add_name">
                                  <h6>{{plus.product_name}}</h6>
                                  <!-- <small class="txt-greenlake">流量 1GB/每日</small> -->
                                </div>
                              </div>
                              <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                                <p>{{plus.qty}}</p>
                              </div>
                              <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                                <div>
                                  <p>Free</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <div class="cart_tr tr-mutiple add_discount"
                    v-if="data.promotionEvent.code
                    || data.discountCoupon.name
                    || data.birthdayCoupon.name"
                    @click="showDiscount = !showDiscount"
                    >
                      <div class="cart_td td-mutiple_title">
                        <p class="mutiple_title txt-white">
                          <span>
                            優惠
                          </span>
                        </p>
                      </div>
                      <div class="cart_td"
                      :class="{'td-add_inner': showDiscount}"
                      v-if="data.promotionEvent.code">
                        <div class="cart_tr">
                          <div class="cart_td td-discount_choose">
                            <p style="margin-right:10px">優惠代碼</p>
                            <input
                              type="text"
                              class="readonly p"
                              readonly
                              :value="data.promotionEvent.code"
                              style="margin:3px 0">
                          </div>
                        </div>
                      </div>
                      <div class="cart_td"
                      :class="{'td-add_inner': showDiscount}"
                      v-if="data.discountCoupon.name">
                        <div class="cart_tr">
                          <div class="cart_td td-discount_choose">
                            <p style="margin-right:10px">折價券</p>
                            <input
                              type="text"
                              class="readonly p"
                              readonly
                              :value="data.discountCoupon.name"
                              style="margin:3px 0">
                          </div>
                        </div>
                      </div>
                      <div class="cart_td"
                      :class="{'td-add_inner': showDiscount}"
                      v-if="data.birthdayCoupon.name">
                        <div class="cart_tr">
                          <div class="cart_td td-discount_choose">
                            <p style="margin-right:10px">生日券</p>
                            <input
                              type="text"
                              class="readonly p"
                              readonly
                              :value="data.birthdayCoupon.name"
                              style="margin:3px 0">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cart_method" v-if="orderData.info">
                  <!-- 選擇取件方式 -->
                  <div class="method_choose">
                    <div class="method_item" v-if="orderData.info.flight_abroad">
                      <p class="txt-white">航班時間</p>
                      <input type="text" class="readonly small"
                      :value="orderData.info.flight_abroad" readonly>
                    </div>
                    <div class="method_item" v-if="orderData.info.take_way">
                      <p class="txt-white">取件方式</p>
                      <input
                        type="text"
                        class="readonly small"
                        :value="orderData.info.take_way"
                        readonly>
                      <div id="method_getdate-wifi"
                      class="method_date wifi small" v-if="takeWayMethod(orderData.info.take_way)">
                        取件日
                        {{takeWayMethod(orderData.info.take_way)}}
                      </div>
                    </div>
                    <div class="method_item" v-if="orderData.info.return_way">
                      <p class="txt-white">歸還方式</p>
                      <input
                        type="text"
                        class="readonly small"
                        :value="orderData.info.return_way"
                        readonly>
                      <div id="method_getdate-wifi" class="method_date wifi small">
                        歸還日 {{getReturnTakeDay}}
                      </div>
                    </div>
                    <!-- 運費 -->
                    <div class="method_item" v-if="parseInt(orderData.info.shipping_fee) > 0">
                      <p class="txt-white">運費</p>
                      <p class="txt-white">
                          ${{orderData.info.shipping_fee}}
                      </p>
                    </div>
                  </div>
                  <div class="method_shipping" v-if="parseInt(orderData.info.shipping_fee) === 0">
                    <p class="txt-white">免運費</p>
                  </div>
                </div>
              </div>
              <!-- <div class="cart_area travel_cart">
                <div class="cart_head">
                  <div class="cart_tr txt-white">
                    <div class="cart_td td-pic"><h6 class="txt-bold">購買商品</h6></div>
                    <div class="cart_td td-name"><small>名稱</small></div>
                    <div class="cart_td td-time_select txt-center"><small>時間</small></div>
                    <div class="cart_td td-prize txt-center"><small>單價</small></div>
                    <div class="cart_td td-count txt-center"><small>數量</small></div>
                    <div class="cart_td td-total txt-center"><small>小計</small></div>
                  </div>
                </div>

                <div class="cart_item_box">
                  <div class="cart_item"> -->
                      <!-- 商品 -->
                      <!-- <div class="cart_tr">
                        <div class="cart_td td-pic">
                          <div class="picbox">
                            <figure
                              class="cart_pic"
                              :style="
                                `background-image: url(
                                  ${require('@/assets/img/shopping/product_3.png')}
                                );`
                              ">
                            </figure>
                          </div>
                        </div>
                        <div class="cart_td td-name">
                          <div class="tag small">wi-fi分享器</div>
                          <h6 class="txt-bold">日本藍鑽石</h6>
                          <small class="txt-greenlake">流量: 吃到飽</small>
                        </div>
                          <div class="cart_td td-time_select center rwd-travel" data-rwd="時間">
                            <p class="small">2020/08/08</p>
                          </div>
                          <div class="cart_td td-prize center rwd-travel" data-rwd="單價">
                            <div>
                              <p class="txt-redorange">$123 / 天</p>
                              <p class="txt-line-through">$149 / 天</p>
                            </div>
                          </div>
                          <div class="cart_td td-count center rwd-travel" data-rwd="數量">
                            <div class="cart_number" data-max="10">
                              <p>2</p>
                            </div>
                          </div>
                          <div class="cart_td td-total center rwd-travel" data-rwd="小計">
                            <div>
                              <p class="txt-redorange">$2,460</p>
                              <p class="txt-line-through">$2,460</p>
                            </div>
                          </div>
                      </div> -->
                      <!-- 商品 優惠 -->
                      <!-- <div class="cart_tr tr-mutiple add_discount">
                        <div class="cart_td td-mutiple_title">
                          <p class="mutiple_title txt-white">
                            <span>
                              優惠
                            </span>
                          </p>
                        </div>
                        <div class="cart_td td-add_inner">
                          <div class="cart_tr">
                            <div class="cart_td td-discount_choose"> -->
                              <!-- <p style="margin-right:10px">優惠代碼</p>
                              <input
                                type="text"
                                class="readonly p"
                                readonly
                                value="wiho123123"
                                style="margin:3px 0"> -->
                              <!-- <p style="margin-right:10px">折價券</p>
                              <p class="txt-greenlake small col100">8月壽星生日快樂！不限金額單品現折 $200</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div> -->

                <!-- <div class="cart_method"> -->
                  <!-- 選擇取件方式 -->
                  <!-- <div class="method_choose">
                    <div class="method_item">
                      <p class="txt-white">航班時間</p>
                      <input type="text" class="readonly small" value="09:30~12:30" readonly>
                    </div>
                    <div class="method_item">
                      <p class="txt-white">取件方式</p>
                      <input
                        type="text"
                        class="readonly small"
                        value="松山機場國際線第一航廈1樓入境大廳櫃台(07:00~19:00)"
                        readonly>
                      <div id="method_getdate-wifi" class="method_date wifi small">
                        取件日 2020/08/20
                      </div>
                    </div>
                  </div> -->
                  <!-- 運費 -->
                  <!-- <div class="method_shipping">
                    <p class="txt-white">運費 $100</p>
                  </div>
                </div>
              </div> -->
              <!-- end -->
            </div>

            <!-- 租借商品配送資訊 -->
            <div class="detail_title">
              <h6 class="txt-bold">配送資訊</h6>
            </div>
            <div class="detail_box" v-if="orderData.info && orderData.expressInfo">
              <div class="detail_item full">
                <p class="mg7 txt-grey">取件方式</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>{{orderData.info.take_way}}</p>
                  </div>
                </div>
              </div>

              <div class="detail_item full" v-if="orderData.expressInfo.address">
                <p class="mg7 txt-grey">配送地址</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>{{orderData.expressInfo.address}}</p>
                  </div>
                </div>
              </div>

              <div class="detail_item full">
                <p class="mg7 txt-grey">收件人</p>
                <div class="fill_in">
                  <div class="col100">
                    <p v-if="orderData.expressInfo.name">
                      {{orderData.expressInfo.name}} ({{orderData.expressInfo.sex}})
                    </p>
                  </div>
                </div>
              </div>

              <div class="detail_item full">
                <p class="mg7 txt-grey">手機</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>{{orderData.expressInfo.country_code}} {{orderData.expressInfo.mobile}}</p>
                  </div>
                </div>
              </div>

              <div class="detail_item full">
                <p class="mg7 txt-grey">狀態</p>
                <div class="fill_in m600full">
                  <div class="col100">
                    <!-- 狀態 -->
                    <div class="order_status">
                      <div class="status_box">
                        <!-- 租借商品 -->
                        <div class="status_line_box active">
                          <!-- 當前的狀態加上class="active", 過往狀態加上class="past" -->
                          <div
                          v-for="(cargo, key) in orderData.expressInfo.cargos"
                              :key="`cargo${key}`"
                          class="status_line_item rent"
                          :class="[
                            {active: ifFitSomething(orderData.expressInfo.activeCargos, cargo.id)},
                            {past: key < orderData.expressInfo.activeCargos.length - 1}
                          ]">
                            <span class="ball"></span>
                            <div>
                              <p>{{cargo.name}}</p>
                              <p v-if="orderData.expressInfo.activeCargos[key]"
                                class="txt-grey small">
                                {{
                                  moment(orderData.expressInfo.activeCargos[key].created_at)
                                    .format('YYYY-MM-DD')
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 購買商品配送資訊 -->
            <!-- <div class="detail_title">
                <h6 class="txt-bold">購買商品配送資訊</h6>
            </div>
            <div class="detail_box">
                <div class="detail_item full">
                    <p class="mg7 txt-grey">取件方式</p>
                    <div class="fill_in">
                        <div class="col100">
                            <p>宅配</p>
                        </div>
                    </div>
                </div>

                <div class="detail_item full">
                    <p class="mg7 txt-grey">配送地址</p>
                    <div class="fill_in">
                        <div class="col100">
                            <p>116 台北市文山區萬安街44巷8號</p>
                        </div>
                    </div>
                </div>

                <div class="detail_item full">
                    <p class="mg7 txt-grey">收件人</p>
                    <div class="fill_in">
                        <div class="col100">
                            <p>詹家宜 (小姐)</p>
                        </div>
                    </div>
                </div>

                <div class="detail_item full">
                    <p class="mg7 txt-grey">手機</p>
                    <div class="fill_in">
                        <div class="col100">
                            <p>+886 0928537575</p>
                        </div>
                    </div>
                </div>

                <div class="detail_item full">
                    <p class="mg7 txt-grey">狀態</p>
                    <div class="fill_in m600full">
                        <div class="col100">
                            <div class="order_status">
                                <div class="status_box">
                                    <div class="status_line_box active">
                                        <div class="status_line_item buy past">
                                            <span class="ball"></span>
                                            <div>
                                                <p>成立訂單</p>
                                                <p class="txt-grey small">06/30 17:30</p>
                                            </div>
                                        </div>
                                        <div class="status_line_item buy past">
                                            <span class="ball"></span>
                                            <div>
                                                <p>物流出貨</p>
                                                <p class="txt-grey small">07/01 10:30</p>
                                                <p class="txt-grey small">
                                                  包裹碼: <span class="txt-blue">17263848248</span>
                                                </p>
                                                <a href="#" class="status_to_search small">前往查詢</a>
                                            </div>
                                        </div>
                                        <div class="status_line_item buy active">
                                            <span class="ball"></span>
                                            <div>
                                                <p>到達</p>
                                                <p class="txt-grey small">06/30 17:30</p>
                                            </div>
                                        </div>
                                        <div class="status_line_item buy">
                                            <span class="ball"></span>
                                            <div>
                                                <p>取貨成功</p>
                                                <p class="txt-grey small">06/30 17:30</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- 付款資訊 -->
            <div class="detail_title">
              <h6 class="txt-bold">付款資訊</h6>
            </div>
            <div class="detail_box" v-if="orderData.paymentInfo && orderData.info">
              <div class="detail_item full">
                <p class="mg7 txt-grey">訂購人</p>
                <div class="fill_in">
                  <div class="col100" v-if="orderData.paymentInfo.name">
                    <p>{{orderData.paymentInfo.name}} ({{orderData.paymentInfo.sex}})</p>
                  </div>
                </div>
              </div>
              <div class="detail_item full">
                <p class="mg7 txt-grey">總金額</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>
                      <vue-numeric
                      read-only currency="$"
                      separator=","
                      :value="orderData.info.total_amount"></vue-numeric>
                    </p>
                  </div>
                </div>
              </div>
              <div class="detail_item full">
                <p class="mg7 txt-grey">付款方式</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>{{orderData.paymentInfo.pay_way}}</p>
                  </div>
                </div>
              </div>

              <!-- ATM 轉帳會有的欄位 -->
              <div class="detail_item full" v-if="orderData.paymentInfo.pay_way === 'ATM'">
                <p class="mg7 txt-grey">銀行代碼</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>013 (國泰世華銀行)</p>
                  </div>
                </div>
              </div>
              <div class="detail_item full" v-if="orderData.paymentInfo.pay_way === 'ATM'">
                <p class="mg7 txt-grey">轉帳帳號</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>xxxxxxxxxxxxxxxx</p>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="detail_item full">
                <p class="mg7 txt-grey">狀態</p>
                <div class="fill_in">
                  <div class="col100">
                    <p class="txt-greenlake">{{orderData.paymentInfo.status}}</p>
                  </div>
                </div>
              </div>
              <!-- 未付款 -->
              <div class="detail_item" v-if="orderData.paymentInfo.pay_way === 'ATM'">
                <div class="fill_in">
                  <div class="col100">
                    <div class="pay_note">
                      <h6 class="txt-bold">請於 2 日內至實體 ATM 或網路銀行進行轉帳 </h6>
                      <p class="small txt-grey">※ 您的付款時間可能會影響商品的出貨時間</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 發票資訊 -->
            <div class="detail_title">
              <h6 class="txt-bold">發票資訊</h6>
            </div>
            <div class="detail_box" v-if="orderData.invoiceInfo">
              <div class="detail_item full">
                <p class="mg7 txt-grey">發票</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>{{orderData.invoiceInfo.invoice}}</p>
                  </div>
                </div>
              </div>

              <div class="detail_item full">
                <p class="mg7 txt-grey">統一編號</p>
                <div class="fill_in">
                  <div class="col100">
                    <p>{{orderData.invoiceInfo.company_no}}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- 訂單備註 -->
            <div class="detail_title">
              <h6 class="txt-bold">訂單備註</h6>
            </div>
            <div class="detail_box" v-if="orderData.noteInfo && orderData.noteInfo.length > 0">
              <p v-for="(data, i) in orderData.noteInfo" :key="`note_${i}`">{{data.note}}</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import requestApi from '@/lib/http';
import moment from 'moment';

import '@/assets/scss/questionnaire.scss';

export default {
  name: 'OrderDetail',
  components: {
    Header,
  },
  data() {
    return {
      orderData: {},
      ifInfoShow: false,
      takeways: [],
      showDiscount: true,
    };
  },
  methods: {
    moment,
    ifFitSomething(arr, target) {
      let flag = false;
      arr.forEach((elem) => {
        if (target === elem.cargo_status_id) {
          flag = true;
        }
      });
      return flag;
    },
    toggleIfInfoShow() {
      this.ifInfoShow = !this.ifInfoShow;
    },
    takeWayMethod(data) {
      let name;
      this.takeways.forEach((element) => {
        if (element.text === data) {
          name = element.parent;
        }
      });
      if ((name === '宅配' || name === '超取') && this.getCurrentTakeDay) {
        return moment(this.getCurrentTakeDay).subtract(1, 'd').format('YYYY-MM-DD');
      }
      return this.getCurrentTakeDay;
    },
    async getOrders(id) {
      this.orderData = {};
      const { data } = await requestApi('member.getOrderInfo', { order_id: id });
      this.orderData = {
        ...data,
      };
    },
  },
  computed: {
    getCurrentTakeDay() {
      let date = null;
      if (this.orderData && this.orderData.details.length > 0) {
        this.orderData.details.forEach((item) => {
          console.log(item);
          if (item.backhome_at && item.abroad_at) {
            if (date) {
              if (new Date(date).getTime() / 1000 > new Date(item.abroad_at).getTime() / 1000) {
                date = item.abroad_at;
              }
            } else {
              date = item.abroad_at;
            }
          }
        });
      }
      return date;
    },
    getReturnTakeDay() {
      let date = null;
      if (this.orderData && this.orderData.details.length > 0) {
        this.orderData.details.forEach((item) => {
          if (item.backhome_at && item.abroad_at) {
            if (date) {
              if (new Date(date).getTime() / 1000 < new Date(item.backhome_at).getTime() / 1000) {
                date = item.backhome_at;
              }
            } else {
              date = item.backhome_at;
            }
          }
        });
      }
      return date;
    },
  },
  mounted() {
    const getTakeWays = new Promise((resolve, reject) => {
      const result = requestApi('req.getTakeWays');
      result.then((res) => {
        if (res.status) {
          if (res.data.length > 0) {
            const arr = [];
            res.data.forEach((location) => {
              arr.push({
                text: location[0].name,
                value: location[0].id,
                disable: true,
              });
              const takeways = location[0].options;
              if (takeways && Object.keys(takeways).length > 0) {
                Object.keys(takeways).forEach((key) => {
                  arr.push({
                    text: takeways[key].option,
                    id: takeways[key].id,
                    parent: location[0].name,
                    parent_id: location[0].id,
                    disable: false,
                  });
                });
              }
            });
            resolve(arr);
          } else {
            reject(new Error('查無資料'));
          }
        } else {
          reject(new Error(res.message));
        }
      });
    });
    getTakeWays.then((takeways) => {
      this.takeways = takeways;
    });
    this.getOrders(this.$route.params.id);
  },
};
</script>
